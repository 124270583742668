import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FcGoogle } from 'react-icons/fc';
import usersData from './dados/json/users.json';
import { TopAuthenticatedGoogle, TopAuthenticatedNotGoogle, PageNotAuthenticated } from './componentes/Autenticacao.js';
import { DisciplinaModulo } from './componentes/DisciplinaModulo.js';


function CredenciaisNaoAutorizadas(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
                    <h4>Acesso negado</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p align='center'>Introduziu Credenciais erradas pelo que não está autorizado a entrar na plataforma</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Sair</Button>
            </Modal.Footer>
        </Modal>
    );
}

function App() {
    const [user, setUser] = useState(null);
    const userNotGoogleRef = useRef(null);
    const [profile, setProfile] = useState(null);
    const [isUserAllowed, setIsUserAllowed] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const selectedUserNameRef = useRef(null);
    const [loginUserType, setLoginUserType] = useState(null);

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setUser(codeResponse);
            setLoginUserType('google');
        },
        onError: (error) => console.log('Login Failed:', error),
    });

    const loginNotGoogle = (email, password) => {
        const userExists = checkUserExistsNotGoogle(email, password);
        const userProfile = { email, userId: userExists.userId, userName: userExists.userName };
        userNotGoogleRef.current = userProfile;
        setProfile(userProfile);
        if (userExists.exists) {
            setSelectedUserId(userExists.userId);
            selectedUserNameRef.current = userExists.userName;
            setIsUserAllowed(true);
            setLoginUserType('notGoogle');
        } else {
            setIsUserAllowed(false);
            setModalShow(true);
        }
    };

    useEffect(() => {
        if (user && user.access_token) {
            fetch(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setProfile(data);
                    const userExists = checkUserExists(data.email);
                    setIsUserAllowed(userExists);
                    if (!userExists) {
                        setModalShow(true);
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [user]);

    useEffect(() => {
        if (!modalShow) {
            setProfile(null);
            setIsUserAllowed(false);
            setUser(null);
            setLoginUserType(null);
            userNotGoogleRef.current = null;
        }
    }, [modalShow]);

    const logOut = () => {
        googleLogout();
        setProfile(null);
        setIsUserAllowed(false);
        setUser(null);
        setLoginUserType(null);
        userNotGoogleRef.current = null;
        selectedUserNameRef.current = null;
    };

    const checkUserExists = (email) => {
        const user = usersData.users.find((user) => user.email === email);
        if (user) {
            setSelectedUserId(user.id);
            selectedUserNameRef.current = user.nome;
            return true;
        }
        return false;
    };

    const checkUserExistsNotGoogle = (email, password) => {
        const userNotGoogle = usersData.users.find((userNotGoogle) => userNotGoogle.email === email && userNotGoogle.password === password);
        if (userNotGoogle) {
            return { exists: true, userId: userNotGoogle.id, userName: userNotGoogle.nome };
        }
        return { exists: false, userId: null, userName: null };
    };

    return (
        <div className="App">
            {!user && !userNotGoogleRef.current ? (
                <PageNotAuthenticated FcGoogle={FcGoogle} loginGoogle={loginGoogle} loginNotGoogle={loginNotGoogle} />
            ) : (
                isUserAllowed ? (
                    <>
                        {loginUserType === 'google' ? (
                            <TopAuthenticatedGoogle profile={profile} logOut={logOut} />
                        ) : (
                            <TopAuthenticatedNotGoogle profile={profile} logOut={logOut} />
                        )}
                        <DisciplinaModulo selectedUserId={selectedUserId} />
                    </>
                ) : (
                    <CredenciaisNaoAutorizadas
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        loginUserType={loginUserType}
                    />
                )
            )}
        </div>
    );
}

export default App;